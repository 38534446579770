import axios from "axios";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import InputField from "../components/InputField";
import ProgressBar from "../components/ProgressBar";

export default function Consent({
  user,
  consent,
  setConsent,
  anamnese,
  startDate,
}) {
  const navigate = useNavigate();
  const [formValid, setFormValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const addUser = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API}/user`, {
        user,
        anamnese,
        appointment: startDate,
        consent,
      })
      .then((res) => {
        const { id, unique_id } = res.data.data;
        axios
          .post(`${process.env.REACT_APP_API}/mail`, {
            type: "checkin",
            id,
            unique_id,
          })
          .catch((e) => console.log(e));
        return { id, unique_id };
      })
      .then((res) => {
        const { id, unique_id } = res;
        navigate(`/checkedin/${id}_${unique_id}`);
      })
      .catch((e) => {
        alert("Fehler beim Einchecken");
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCurrentDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "." + mm + "." + yyyy;
    return today;
  };

  const handleCheckboxCheck = (e) => {
    setConsent({
      ...consent,
      [e.target.name]: consent[e.target.name] === "0" ? "1" : "0",
    });
  };

  useEffect(() => {
    let emptyFields = 0;
    Object.keys(consent).forEach((key) => {
      if (key === "fragen" && (consent[key] === "0" || consent[key] === null)) {
        emptyFields++;
      }
      if (
        key === "einwilligung" &&
        (consent[key] === "0" || consent[key] === null)
      ) {
        emptyFields++;
      }
      if (key === "ablehnung" && consent[key] === "1") {
        emptyFields++;
      }
    });
    if (consent.ort === "") {
      emptyFields++;
    }
    if (emptyFields > 0) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [consent]);

  return (
    <>
      <Header />
      <h1 className="py-8 text-2xl text-center">Einwilligungserklärung</h1>
      <ProgressBar active={4} />
      <br />

      <>
        <div className="my-4">
          <p>
            <span className="text-xs">Name der zu impfenden Person:</span>
            <br />
            <span className="font-bold">
              {user.fn} {user.ln}
            </span>
          </p>
          <p>
            <span className="text-xs">Geburtsdatum:</span>
            <br />
            <span className="font-bold">{user.dob}</span>
          </p>
          <p>
            <span className="text-xs">Anschrift:</span>
            <br />
            <span className="font-bold">
              {user.street}, {user.zip} {user.city}
            </span>
          </p>
          <p className="mt-8 mb-4 font-bold prose">
            Ich habe den Inhalt des Aufklärungsmerkblattes zur Kenntnis genommen
            und hatte die Möglichkeit zu einem ausführlichen Gespräch mit meiner
            Impfärztin / meinem Impfarzt.
          </p>
        </div>
        <div>
          <div className="my-4">
            <label className="py-2">
              <input
                type="checkbox"
                checked={consent.fragen === "1"}
                name="fragen"
                className="mr-2"
                onChange={handleCheckboxCheck}
              />{" "}
              Ich habe keine weiteren Fragen und verzichte ausdrücklich auf das
              ärztliche Aufklärungsgespräch.
            </label>
          </div>

          <div className="my-4">
            <label className="py-2">
              <input
                type="checkbox"
                checked={consent.einwilligung === "1"}
                name="einwilligung"
                className="mr-2"
                onChange={handleCheckboxCheck}
              />{" "}
              Ich willige in die vorgeschlagene Impfung gegen COVID-19 mit
              mRNA-Impfstoff ein.
            </label>
          </div>

          <div className="my-4">
            <label className="py-2">
              <input
                type="checkbox"
                checked={consent.ablehnung === "1"}
                name="ablehnung"
                className="mr-2"
                onChange={handleCheckboxCheck}
              />{" "}
              Ich lehne die Impfung ab.
            </label>
          </div>
        </div>
        {(consent.ablehnung === "0" || consent.ablehnung === null) && (
          <>
            <div>
              <InputField
                value={consent.anmerkungen}
                handleChange={(e) =>
                  setConsent({
                    ...consent,
                    anmerkungen: e.target.value,
                  })
                }
                className="mt-10"
                name="anmerkungen"
                placeholder="Anmerkungen"
                errors={null}
              />

              <div className="flex items-end gap-4">
                <InputField
                  value={consent.ort}
                  handleChange={(e) =>
                    setConsent({
                      ...consent,
                      ort: e.target.value,
                    })
                  }
                  className="flex-1 mt-10"
                  name="ort"
                  placeholder="Ort"
                  errors={null}
                />
                <div className="relative flex-1 text-right bottom-3">
                  den {getCurrentDate()}
                </div>
              </div>
            </div>

            <p className="my-4 prose">
              <span className="font-bold">
                Bei fehlender Einwilligungsfähigkeit der zu impfenden Person:
                <br />
              </span>
              Bei Sorgeberechtigten zusätzlich:
              <br />
              <span className="italic">
                Ich erkläre, dass ich von etwaigen anderen sorgeberechtigten
                Personen für die Einwilligung ermächtigt wurde.
              </span>
            </p>

            <p className="my-4 prose">
              Falls die zu impfende Person nicht einwilligungsfähig ist, bitte
              auch Namen und Kontaktdaten der zur Einwilligung berechtigten
              Person (Sorgeberechtigte, Vorsorgeberechtigte oder
              Betreuerin/Betreuer) angeben:
            </p>

            <InputField
              value={consent.berPersonName}
              handleChange={(e) =>
                setConsent({
                  ...consent,
                  berPersonName: e.target.value,
                })
              }
              className="mt-10"
              name="berPersonName"
              placeholder="Name, Vorname"
              errors={null}
            />

            <InputField
              value={consent.berPersonTel}
              handleChange={(e) =>
                setConsent({
                  ...consent,
                  berPersonTel: e.target.value,
                })
              }
              className="mt-10"
              name="berPersonTel"
              placeholder="Telefonnummer"
              errors={null}
            />

            <InputField
              value={consent.berPersonMail}
              handleChange={(e) =>
                setConsent({
                  ...consent,
                  berPersonMail: e.target.value,
                })
              }
              className="mt-10"
              name="berPersonMail"
              placeholder="E-Mail-Adresse"
              errors={null}
            />

            <p className="mt-8 text-xs prose text-center text-slate-500">
              Ihre Einwilligung können Sie jeder Zeit schriftlich, z.B. per
              E-Mail an{" "}
              <a href={`mailto:${process.env.REACT_APP_MAIL}`}>
                {process.env.REACT_APP_MAIL}
              </a>{" "}
              widerrufen.
              <br />
              Mit Ihrer Einwilligung stimmen Sie unseren Datenschutzrichtlinien
              zu.
              <br />
              <NavLink to="/datenschutz">
                Diese können Sie auf dieser Seite einsehen.
              </NavLink>
            </p>

            <Button
              title="Ich willige ein"
              className="mt-8"
              onClick={() => addUser()}
              disabled={!formValid || loading}
            />
          </>
        )}
        {consent.ablehnung === "1" && (
          <div className="p-4 text-center text-white bg-red-700 rounded-xl">
            Bei abgelehnter Impfung ist eine Anmeldung nicht möglich.
          </div>
        )}
      </>

      <Footer />
    </>
  );
}
