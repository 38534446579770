import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import Loading from "../components/Loading";
import moment from "moment";
import { NavLink } from "react-router-dom";
import InputField from "../components/InputField";
import Button from "../components/Button";
import UserRow from "../components/UserRow";

export default function Search() {
  const token = Cookies.get("token");
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const handleSearch = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API}/user/search`,
        { search },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setUsers(res.data.users);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        alert("Fehler beim Laden der User");
        console.log(err);
      });
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="py-8 text-xl text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="relative inline w-4 h-4 mr-2 -top-[3px]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
          Suche
        </h1>
        <NavLink to="/impfadmin" className="hover:opacity-80">
          - zurück
        </NavLink>
      </div>
      <div className="space-y-4">
        <InputField
          name="search"
          placeholder="Suche"
          icon="search"
          value={search}
          handleChange={(e) => setSearch(e.target.value)}
        />
        <Button title="Suchen" onClick={handleSearch} />
      </div>
      <div className="h-4"></div>
      <div className="text-center">{`${
        users ? users.length : 0
      } User gefunden`}</div>
      <div className="h-4"></div>
      {loading && <Loading what="Nutzer" />}
      {!loading &&
        users &&
        users.map((user) => <UserRow key={user.id} user={user} />)}
    </>
  );
}
