export const user = {
  fn: "",
  ln: "",
  mobile: "",
  mail: "",
  mail2: "",
  street: "",
  zip: "",
  city: "",
  dob: "",
};
export const dummyuser = {
  fn: "Phil",
  ln: "Harmonie",
  mobile: "015256881259",
  mail: "phil@harmonie.media",
  mail2: "phil@harmonie.media",
  street: "Fichtenweg 4",
  zip: "64839",
  city: "Münster",
  dob: "12.10.1987",
};
export const anamnesis = {
  fieber: null,
  geimpft: null,
  geimpft_covid: null,
  geimpft_covid_datum1: "",
  geimpft_covid_impfstoff1: "",
  geimpft_covid_datum2: "",
  geimpft_covid_impfstoff2: "",
  geimpft_covid_datum3: "",
  geimpft_covid_impfstoff3: "",
  impfreaktion: null,
  ungewoehnliche_reaktionen: null,
  ungewoehnliche_reaktionen_welche: "",
  infektion: null,
  infektion_datum: "",
  chronische_erkrankung: null,
  chronische_erkrankungen: "",
  blutgerinnerungsstoerung: null,
  allergie: null,
  allergien: "",
  fruehe_ungewoehnliche_reaktion: null,
  fruehe_ungewoehnliche_reaktionen: "",
  schwanger: null,
  ssw: "",
};
export const dummyanamnesis = {
  fieber: "1",
  geimpft: "1",
  geimpft_covid: "1",
  geimpft_covid_datum1: "test",
  geimpft_covid_impfstoff1: "test",
  geimpft_covid_datum2: "test",
  geimpft_covid_impfstoff2: "test",
  geimpft_covid_datum3: "test",
  geimpft_covid_impfstoff3: "test",
  impfreaktion: "1",
  ungewoehnliche_reaktionen: "1",
  ungewoehnliche_reaktionen_welche: "test",
  infektion: "1",
  infektion_datum: "test",
  chronische_erkrankung: "1",
  chronische_erkrankungen: "test",
  blutgerinnerungsstoerung: "1",
  allergie: "1",
  allergien: "test",
  fruehe_ungewoehnliche_reaktion: "1",
  fruehe_ungewoehnliche_reaktionen: "test",
  schwanger: "1",
  ssw: "test",
};
export const consent = {
  fragen: null,
  einwilligung: null,
  ablehnung: null,
  anmerkungen: "",
  ort: "",
  berPersonName: "",
  berPersonTel: "",
  berPersonMail: "",
};
export const dummyconsent = {
  fragen: "1",
  einwilligung: "1",
  ablehnung: null,
  anmerkungen: "Ich habe etwas anzumerken",
  ort: "Frankfurt am Main",
  berPersonName: "Max Mustermann",
  berPersonTel: "0123456789",
  berPersonMail: "test@test.de",
};
export const initialStartDate = () => {
  var coeff = 1000 * 60 * 5;
  var date = new Date();
  var rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
  return rounded;
};
