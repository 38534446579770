import { NavLink } from "react-router-dom";

export default function NotFound() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="block w-6 h-6 mx-auto my-16 text-red-700 animate-ping"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <div className="text-xl text-center text-slate-800">
        Diese Seite existiert nicht
      </div>
      <NavLink to="/" className="block mt-8 text-center">
        zur Startseite
      </NavLink>
    </>
  );
}
