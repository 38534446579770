import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Header from "../components/Header";
import InputField from "../components/InputField";
import ProgressBar from "../components/ProgressBar";
import doctor from "../images/corona-man.svg";
import { Formik } from "formik";
import Footer from "../components/Footer";
import { useEffect, useState, forwardRef } from "react";
import axios from "axios";
import DatePicker, { registerLocale } from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import de from "date-fns/locale/de";
import "../react-datepicker/datepicker.scss";
import Loading from "../components/Loading";

registerLocale("de", de);

export default function CheckIn({ user, setUser, startDate, setStartDate }) {
  const navigate = useNavigate();

  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateActive, setDateActive] = useState(false);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const isWeekday = (date) => {
    const day = date.getDay(date);
    const days = process.env.REACT_APP_OPEN_DAYS.split(",").map((d) =>
      parseInt(d)
    );
    return days.includes(day);
  };

  const DateSelector = forwardRef(({ value, onClick, active = false }, ref) => (
    <div className="flex">
      <input
        onClick={onClick}
        ref={ref}
        id="prename"
        type="prename"
        name="prename"
        required
        defaultValue={`${value} Uhr`}
        className={`w-full p-4 text-center text-white cursor-pointer rounded-tl-xl rounded-bl-xl ${
          active ? "bg-green-700" : "bg-yellow-600"
        }`}
        readOnly={true}
      />
      <div
        className={`flex items-center justify-center p-4 rounded-tr-xl rounded-br-xl ${
          active ? "bg-green-700" : "bg-yellow-600"
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`w-6 h-6 ${active ? "text-green-200" : "text-yellow-200"}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      </div>
    </div>
  ));

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/appointments`)
      .then((res) => {
        const appointments = res.data.appointments;

        setAppointments(
          appointments.map((a) => {
            var t = a.date.split(/[- :]/);
            var d = new Date(
              Date.UTC(t[0], t[1] - 1, t[2], t[3] - 2, t[4], t[5])
            );
            return d;
          })
        );
      })
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        alert("Fehler beim Laden der Termine");
        console.log(e);
      });
  }, []);

  return (
    <>
      <Header />
      <div className="flex pt-16">
        <div className="pt-4">
          <h1 className="mb-2 text-3xl">Impfanmeldung</h1>
          <span className="text-xs">
            <strong>Schutzimpfung gegen COVID-19</strong>
            <br />
            (Corona Virus Disease 2019)
          </span>
        </div>
        <img src={doctor} alt="Doktor" className="relative w-2/3 -top-8" />
      </div>
      <div className="mb-8 text-xs">
        Grundimmunisierung und Auffrischimpfung mit mRNA-Impfstoff
        <br />
        <strong>
          Comirnaty® 10 μg und 30 μg von BioNTech / Pfizer und Spikevax® von
          Moderna
        </strong>
        <br /> <i>Stand: 21. Dezember 2021</i>
        <br />
        Diese Informationen liegen in leichter Sprache und Fremdsprachen vor:
        <br />
        <a
          href="www.rki.de/DE/Content/Infekt/Impfen/Materialien/COVID-19-Aufklaerungsbogen-Tab.html"
          target="_blank"
          className="underline"
        >
          www.rki.de/DE/Content/Infekt/Impfen/Materialien/COVID-19-Aufklaerungsbogen-Tab.html
        </a>
      </div>
      <ProgressBar active={1} />
      {loading && <Loading what="Termine" />}
      {!loading && (
        <Formik
          initialValues={{
            fn: "",
            ln: "",
            mobile: "",
            mail: "",
            mail2: "",
            street: "",
            zip: "",
            city: "",
            dob: "",
          }}
          validate={(values) => {
            const errors = {};
            Object.keys(user).forEach((key) => {
              if (!values[key] && user[key]) {
                values[key] = user[key];
              }
            });
            if (!values.fn) {
              errors.fn = "Required";
            }
            if (!values.ln) {
              errors.ln = "Required";
            }
            if (!values.mobile) {
              errors.mobile = "Required";
            }
            if (!values.mail) {
              errors.mail = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.mail)
            ) {
              errors.mail = "Invalid mail address";
            }
            if (!values.mail2) {
              errors.mail2 = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.mail2)
            ) {
              errors.mail2 = "Invalid mail address";
            } else if (values.mail !== values.mail2) {
              errors.mail2 = "Mail does not match";
            }
            if (!values.street) {
              errors.street = "Required";
            }
            if (!values.zip) {
              errors.zip = "Required";
            } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(values.zip)) {
              errors.zip = "Invalid ZIP code";
            }
            if (!values.city) {
              errors.city = "Required";
            }
            if (!values.dob) {
              errors.dob = "Required";
            } else if (
              !/^(3[01]|[12][0-9]|0[1-9])\.(1[012]|0[1-9])\.((?:19|20)\d{2})$/i.test(
                values.dob
              )
            ) {
              errors.dob = "Invalid Birthday";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setUser({ ...values });
            setSubmitting(false);
            navigate("/check");
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="py-4 mt-4 mb-8 text-center">
                <h2>Termin wählen</h2>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setDateActive(true);
                  }}
                  showTimeSelect
                  minDate={new Date()}
                  minTime={setHours(
                    setMinutes(new Date(), 0),
                    parseInt(process.env.REACT_APP_OPEN_FROM)
                  )}
                  maxTime={setHours(
                    setMinutes(new Date(), 0),
                    parseInt(process.env.REACT_APP_OPEN_TO)
                  )}
                  filterTime={filterPassedTime}
                  filterDate={isWeekday}
                  dateFormat="dd.MM.yyyy HH:mm"
                  locale="de"
                  timeIntervals={parseInt(process.env.REACT_APP_SLOT_STEPS)}
                  customInput={<DateSelector active={dateActive} />}
                  excludeTimes={appointments}
                  name="date"
                />
              </div>
              <div className="py-4 space-y-8">
                <div className="flex gap-4">
                  <InputField
                    placeholder="Vorname"
                    name="fn"
                    value={values.fn || user.fn}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors}
                    icon="person"
                    validation="required"
                  />
                  <InputField
                    placeholder="Nachname"
                    name="ln"
                    value={values.ln || user.ln}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors}
                    icon="personFill"
                    validation="required"
                  />
                </div>
                <InputField
                  placeholder="Handynummer"
                  name="mobile"
                  value={values.mobile || user.mobile}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  icon="mobile"
                  validation="required"
                />
                <InputField
                  placeholder="E-Mail-Adresse"
                  name="mail"
                  value={values.mail || user.mail}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  icon="mail"
                  validation="required||mail"
                />
                <InputField
                  placeholder="E-Mail-Adresse wiederholen"
                  name="mail2"
                  value={values.mail2 || user.mail2}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  icon="mail"
                  validation="required||mail"
                />
                <InputField
                  placeholder="Straße + Hausnummer"
                  name="street"
                  value={values.street || user.street}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  icon="house"
                  validation="required"
                />
                <div className="flex gap-4">
                  <InputField
                    placeholder="PLZ"
                    name="zip"
                    value={values.zip || user.zip}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors}
                    validation="required||zip"
                  />
                  <InputField
                    placeholder="Stadt"
                    name="city"
                    value={values.city || user.city}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors}
                    icon="houseFill"
                    validation="required"
                  />
                </div>
                <InputField
                  placeholder="Geburtsdatum (TT.MM.JJJJ)"
                  name="dob"
                  value={values.dob || user.dob}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  icon="birthday"
                  validation="required||localdate"
                />
                <Button
                  title="Weiter"
                  type="submit"
                  disabled={!isValid || isSubmitting || !dateActive}
                />
              </div>
            </form>
          )}
        </Formik>
      )}
      <Footer />
    </>
  );
}
