import { NavLink, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ProgressBar from "../components/ProgressBar";

export default function CheckData({ user }) {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <h1 className="py-8 text-2xl text-center">Daten prüfen</h1>
      <ProgressBar active={2} />
      <br /> <br />
      <h2 className="py-4 text-xl">
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 inline text-gray-600 relative -top-[2px] animate-pulse"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>{" "}
        Sind alle Angaben korrekt?
      </h2>
      <div className="prose">
        <p>
          <strong className="text-xs font-light text-gray-400">Name</strong>
          <br />
          {user.fn} {user.ln}
        </p>
        <p>
          <strong className="text-xs font-light text-gray-400">
            Anschrift
          </strong>
          <br />
          {user.street}
          <br />
          {user.zip} {user.city}
        </p>
        <p>
          <strong className="text-xs font-light text-gray-400">
            E-Mail-Adresse
          </strong>
          <br />
          {user.mail}
        </p>
        <p>
          <strong className="text-xs font-light text-gray-400">
            Handynummer
          </strong>
          <br />
          {user.mobile}
        </p>
        <p>
          <strong className="text-xs font-light text-gray-400">
            Geburtsdatum
          </strong>
          <br />
          {user.dob}
        </p>
      </div>
      <Button
        title="Alle Daten korrekt"
        className="mt-8"
        onClick={() => navigate("/anamnese")}
      />
      <NavLink
        to="/"
        className="block py-4 text-center transition-all hover:opacity-70"
      >
        Ändern
      </NavLink>
      <Footer />
    </>
  );
}
