import axios from "axios";
import Cookies from "js-cookie";
import { forwardRef, useEffect, useState } from "react";
import Loading from "../components/Loading";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import DatePicker, { registerLocale } from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import parseISO from "date-fns/parseISO";
import de from "date-fns/locale/de";
import "../react-datepicker/datepicker.scss";

registerLocale("de", de);

export default function User() {
  const navigate = useNavigate();
  const params = useParams();
  const token = Cookies.get("token");
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [appointments, setAppointments] = useState([]);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const isWeekday = (date) => {
    const day = date.getDay(date);
    const days = process.env.REACT_APP_OPEN_DAYS.split(",").map((d) =>
      parseInt(d)
    );
    return days.includes(day);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/appointments`)
      .then((res) => {
        const appointments = res.data.appointments;

        setAppointments(
          appointments.map((a) => {
            var t = a.date.split(/[- :]/);
            var d = new Date(
              Date.UTC(t[0], t[1] - 1, t[2], t[3] - 2, t[4], t[5])
            );
            return d;
          })
        );
      })
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        alert("Fehler beim Laden der Termine");
        console.log(e);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/user/${params.uid}/admin`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setUser({
          ...res.data.data.user,
          appointment: res.data.data.appointment.date,
          appointmentId: res.data.data.appointment.id,
        });
        setStartDate(parseISO(res.data.data.appointment.date));
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        alert("Fehler beim Laden des Users");
        console.log(err);
        navigate("/impfadmin");
      });
  }, []);

  const DateSelector = forwardRef(({ value, onClick, active = false }, ref) => (
    <div className="flex mb-4">
      <input
        onClick={onClick}
        ref={ref}
        id="prename"
        type="prename"
        name="prename"
        required
        defaultValue={`${value} Uhr`}
        className={`w-full p-4 text-center text-white cursor-pointer rounded-tl-xl rounded-bl-xl ${
          active ? "bg-green-700" : "bg-yellow-600"
        }`}
        readOnly={true}
      />
      <div
        className={`flex items-center justify-center p-4 rounded-tr-xl rounded-br-xl ${
          active ? "bg-green-700" : "bg-yellow-600"
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`w-6 h-6 ${active ? "text-green-200" : "text-yellow-200"}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      </div>
    </div>
  ));

  const saveAppointment = () => {
    axios
      .put(
        `${process.env.REACT_APP_API}/appointments`,
        { id: user.appointmentId, date: startDate },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        alert("Termin gespeichert");
        navigate("/impfadmin");
      })
      .catch((err) => {
        alert("Fehler beim Speichern des Termines");
        console.log(err);
      });
  };

  const deleteUser = () => {
    axios
      .delete(`${process.env.REACT_APP_API}/user/${user.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        navigate("/impfadmin");
      })
      .catch((err) => {
        alert("Fehler beim Löschen des Benutzers");
        console.log(err);
      });
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="py-8 text-xl text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="relative inline w-4 h-4 mr-2 -top-[3px]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          Benutzer
        </h1>
        <NavLink to="/impfadmin" className="cursor-pointer hover:opacity-80">
          - zurück
        </NavLink>
      </div>
      {loading && <Loading what="Nutzer" />}
      {!loading && user && (
        <>
          <div className="flex justify-between p-4 mb-4 text-xs border-2 rounded-xl border-slate-800">
            <div>
              <span className="text-base font-bold">
                {user.fn} {user.ln}
              </span>
              <br />
              {user.mail}
              <br />
              {user.street}
              <br />
              {user.zip} {user.city}
            </div>
            <div className="text-right">{user.id}</div>
          </div>

          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
            showTimeSelect
            minDate={new Date()}
            minTime={setHours(
              setMinutes(new Date(), 0),
              parseInt(process.env.REACT_APP_OPEN_FROM)
            )}
            maxTime={setHours(
              setMinutes(new Date(), 0),
              parseInt(process.env.REACT_APP_OPEN_TO)
            )}
            filterTime={filterPassedTime}
            filterDate={isWeekday}
            dateFormat="dd.MM.yyyy HH:mm"
            locale="de"
            timeIntervals={parseInt(process.env.REACT_APP_SLOT_STEPS)}
            excludeTimes={appointments}
            customInput={<DateSelector />}
            name="date"
          />
          <Button
            title="Speichern"
            onClick={() => {
              saveAppointment();
            }}
          />
          <div
            className="my-4 text-center text-red-800 cursor-pointer hover:text-red-700"
            onClick={() => deleteUser()}
          >
            Löschen
          </div>
        </>
      )}
    </>
  );
}
