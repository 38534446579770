import axios from "axios";
import moment from "moment";
import Cookies from "js-cookie";
import { NavLink } from "react-router-dom";

export default function UserRow({ user }) {
  const token = Cookies.get("token");
  const printAnamnesis = (id, unique_id) => {
    axios
      .post(
        `${process.env.REACT_APP_API}/pdf`,
        {
          files: [{ id, unique_id }],
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        window.open(
          `${process.env.REACT_APP_API}/Anamnesis/${res.data.filename}`,
          "_blank",
          "fullscreen=yes"
        );
      })
      .catch((err) => {
        alert("Fehler beim Drucken der Anamnese");
        console.log(err);
      });
  };

  return (
    <NavLink
      to={`/impfadmin/user/${user.id}_${user.unique_id}`}
      className="relative flex flex-1 gap-1 mb-4 text-center text-white bg-slate-900 rounded-xl"
    >
      <div className="absolute -rotate-90 top-[50%] translate-y-[-50%] text-xs left-2">
        {user.id}
      </div>
      <div className="flex-1 p-4">
        <span className="text-2xl font-bold">
          {moment(user.appointment).format("DD.MM. - HH:mm")}
        </span>
        <br />
        {user.fn} {user.ln}
      </div>
      <div
        onClick={() => printAnamnesis(user.id, user.unique_id)}
        className="flex items-center justify-center flex-grow-0 p-4 border-l cursor-pointer bg-slate-800 hover:bg-slate-700 rounded-tr-xl rounded-br-xl"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
          />
        </svg>
      </div>
    </NavLink>
  );
}
