export default function Button({
  title,
  onClick,
  disabled = false,
  type = "button",
  className = "",
}) {
  return (
    <button
      className={`cursor-pointer disbaled:cursor-wait py-4 px-8 w-full text-center bg-green-800 hover:bg-green-700 rounded-xl text-white disabled:bg-gray-200 disabled:text-gray-400 transition-all ${className}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {title}
    </button>
  );
}
