import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CheckIn from "./pages/CheckIn";
import CheckData from "./pages/CheckData";
import Anamnesis from "./pages/Anamnesis";
import ScrollToTop from "./components/ScrollToTop";
import Datenschutz from "./pages/Datenschutz";
import CheckedIn from "./pages/CheckedIn";
import Consent from "./pages/Consent";
import Login from "./pages/Login";
import {
  user as initialUser,
  anamnesis as initialAnamnesis,
  consent as initialConsent,
  initialStartDate,
} from "./initialData";
import NotFound from "./components/NotFound";
import Admin from "./pages/Admin";
import AdminRoute from "./helper/AdminRoute";
import Search from "./pages/Search";
import User from "./pages/User";

function App() {
  const [user, setUser] = useState(initialUser);
  const [anamnese, setAnamnese] = useState(initialAnamnesis);
  const [consent, setConsent] = useState(initialConsent);
  const [startDate, setStartDate] = useState(initialStartDate);

  return (
    <div className="box-border mx-auto overflow-hidden w-96 text-slate-800">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route
            path="/"
            exact
            element={
              <CheckIn
                user={user}
                setUser={setUser}
                startDate={startDate}
                setStartDate={setStartDate}
              />
            }
          />
          <Route path="/check" exact element={<CheckData user={user} />} />
          <Route
            path="/anamnese"
            exact
            element={
              <Anamnesis anamnese={anamnese} setAnamnese={setAnamnese} />
            }
          />
          <Route
            path="/einwilligungserklaerung"
            exact
            element={
              <Consent
                user={user}
                consent={consent}
                setConsent={setConsent}
                anamnese={anamnese}
                startDate={startDate}
              />
            }
          />
          <Route path="/datenschutz" exact element={<Datenschutz />} />
          <Route path="/checkedin/:uid" exact element={<CheckedIn />} />
          <Route path="/impflogin" exact element={<Login />} />
          {/* Admin Routes */}
          <Route
            path="/impfadmin"
            exact
            element={
              <AdminRoute>
                <Admin />
              </AdminRoute>
            }
          />
          <Route
            path="/impfadmin/search"
            exact
            element={
              <AdminRoute>
                <Search />
              </AdminRoute>
            }
          />
          <Route
            path="/impfadmin/user/:uid"
            exact
            element={
              <AdminRoute>
                <User />
              </AdminRoute>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
