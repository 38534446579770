import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function CheckedIn() {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [appointment, setAppointment] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/user/${params.uid}`)
      .then((res) => {
        const user = res.data.data.user;
        const appointment = res.data.data.appointment;
        let date = appointment.date.split(" ");
        const day = date[0].split("-");
        const time = date[1].split(":");
        date = `${day[2]}.${day[1]}.${day[0]} - ${time[0]}:${time[1]} Uhr`;
        setUser(user);
        setAppointment({ ...appointment, date });
      })
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        alert("Fehler");
        console.log(e);
      });
  }, [params]);

  return (
    <>
      <Header />
      <br /> <br />
      {loading && (
        <div className="py-8 text-center animate-pulse">Lade Daten ...</div>
      )}
      {!loading && user && (
        <div className="prose text-center">
          <h2>Vielen Dank!</h2>
          <p>
            Sie haben sich erfolfreich zu Ihrer{" "}
            <strong>Schutzimpfung gegen COVID-19</strong> angemeldet.
          </p>
          <h2>Ihr Termin</h2>
          <div className="p-4 text-xl text-center text-white bg-slate-800 rounded-xl">
            {appointment.date}
          </div>
          <p className="mt-8">Ihre Vorgangsnummer lautet:</p>

          <div className="p-4 text-xl border-2 text-slate-800 border-slate-800 rounded-xl">
            {user.id}
          </div>
          <p className="text-slate-500">
            Bitte melden Sich ca. <strong>15 Minuten</strong> vor Ihrem Termin
            mit ihrem Namen und Ihrer Vorgangsnummer an unserem Empfang an.
          </p>
        </div>
      )}
      <Footer />
    </>
  );
}
