import axios from "axios";
import { useEffect, useState } from "react";
import Cockies from "js-cookie";
import Button from "../components/Button";
import InputField from "../components/InputField";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_API + "/login", { email, password })
      .then((res) => {
        Cockies.set("token", res.data.access_token, {
          expires: 1,
        });
        navigate("/impfadmin");
      })
      .catch((err) => {
        alert("Login-Fehler");
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="py-16 text-3xl text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="relative inline w-6 h-6 mr-2 -top-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
          />
        </svg>
        Impf-Login
      </div>
      <div className="flex flex-col gap-8">
        <InputField
          placeholder="E-Mail"
          name="email"
          value={email}
          handleChange={(e) => setEmail(e.target.value)}
          handleBlur={() => {}}
          errors={null}
          icon="mail"
          validation="required"
        />
        <InputField
          placeholder="Passwort"
          type="password"
          name="password"
          value={password}
          handleChange={(e) => setPassword(e.target.value)}
          handleBlur={() => {}}
          errors={null}
          icon="password"
          validation="required"
        />
        <Button
          title="Login"
          onClick={handleLogin}
          disabled={loading || email === "" || password === ""}
        />
      </div>
    </>
  );
}
