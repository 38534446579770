import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import InputField from "../components/InputField";
import ProgressBar from "../components/ProgressBar";

export default function Anamnesis({ anamnese, setAnamnese }) {
  const navigate = useNavigate();
  const [formValid, setFormValid] = useState(false);

  const handleRadioCheck = (e) => {
    setAnamnese({
      ...anamnese,
      [e.target.name]: e.target.value === "true" ? "1" : "0",
    });
  };

  const handleSubmit = () => {
    navigate("/einwilligungserklaerung");
  };

  useEffect(() => {
    let emptyFields = 0;
    Object.values(anamnese).forEach((value) => {
      if (value === null) {
        emptyFields++;
      }
    });
    if (emptyFields > 0) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [anamnese]);

  return (
    <>
      <Header />
      <h1 className="py-8 text-2xl text-center">Anamnese</h1>
      <ProgressBar active={3} />
      <br />
      {/* 1 */}
      <div className="flex gap-4 mt-4">
        <div className="flex items-center justify-center w-6 h-6 p-1 text-gray-500 border rounded-full shrink-0 text-2xs border-grey-400">
          1
        </div>
        <div>
          Besteht bei Ihnen<span className="relative text-2xs -top-2">1</span>{" "}
          derzeit eine akute Erkrankung mit Fieber?
          <div className="flex justify-between pr-4">
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.fieber === "1"}
                name="fieber"
                className="mr-2"
                value={true}
                onChange={handleRadioCheck}
              />{" "}
              ja
            </label>
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.fieber === "0"}
                name="fieber"
                className="mr-2"
                value={false}
                onChange={handleRadioCheck}
              />{" "}
              nein
            </label>
          </div>
        </div>
      </div>
      {/* 2 */}
      <div className="flex gap-4 mt-4">
        <div className="flex items-center justify-center w-6 h-6 p-1 text-gray-500 border rounded-full shrink-0 text-2xs border-grey-400">
          2
        </div>
        <div>
          Sind Sie<span className="relative text-2xs -top-2">1</span> in den
          letzten 14 Tagen geimpft worden?
          <div className="flex justify-between pr-4">
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.geimpft === "1"}
                name="geimpft"
                className="mr-2"
                value={true}
                onChange={handleRadioCheck}
              />{" "}
              ja
            </label>
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.geimpft === "0"}
                name="geimpft"
                className="mr-2"
                value={false}
                onChange={handleRadioCheck}
              />{" "}
              nein
            </label>
          </div>
        </div>
      </div>

      {/* 3 */}
      <div className="flex gap-4 mt-4">
        <div className="flex items-center justify-center w-6 h-6 p-1 text-gray-500 border rounded-full shrink-0 text-2xs border-grey-400">
          3
        </div>
        <div>
          Wurden Sie<span className="relative text-2xs -top-2">1</span> bereits
          gegen COVID-19 geimpft?
          <div className="flex justify-between pr-4">
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.geimpft_covid === "1"}
                name="geimpft_covid"
                className="mr-2"
                value={true}
                onChange={handleRadioCheck}
              />{" "}
              ja
            </label>
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.geimpft_covid === "0"}
                name="geimpft_covid"
                className="mr-2"
                value={false}
                onChange={handleRadioCheck}
              />{" "}
              nein
            </label>
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-6"></div>
        <div className="font-light">
          Wenn ja, wann und mit welchem Impfstoff?
          <br />
          <div className="flex gap-4">
            <InputField
              value={anamnese.geimpft_covid_datum1}
              handleChange={(e) =>
                setAnamnese({
                  ...anamnese,
                  geimpft_covid_datum1: e.target.value,
                })
              }
              className="mt-6"
              name="geimpft_covid_datum1"
              placeholder="Datum"
              errors={null}
            />
            <InputField
              value={anamnese.geimpft_covid_impfstoff1}
              handleChange={(e) =>
                setAnamnese({
                  ...anamnese,
                  geimpft_covid_impfstoff1: e.target.value,
                })
              }
              className="mt-6"
              name="geimpft_covid_impfstoff1"
              placeholder="Impfstoff"
              errors={null}
            />
          </div>
          <div className="flex gap-4">
            <InputField
              value={anamnese.geimpft_covid_datum2}
              handleChange={(e) =>
                setAnamnese({
                  ...anamnese,
                  geimpft_covid_datum2: e.target.value,
                })
              }
              className="mt-6"
              name="geimpft_covid_datum2"
              placeholder="Datum"
              errors={null}
            />
            <InputField
              value={anamnese.geimpft_covid_impfstoff2}
              handleChange={(e) =>
                setAnamnese({
                  ...anamnese,
                  geimpft_covid_impfstoff2: e.target.value,
                })
              }
              className="mt-6"
              name="geimpft_covid_impfstoff2"
              placeholder="Impfstoff"
              errors={null}
            />
          </div>
          <div className="flex gap-4">
            <InputField
              value={anamnese.geimpft_covid_datum3}
              handleChange={(e) =>
                setAnamnese({
                  ...anamnese,
                  geimpft_covid_datum3: e.target.value,
                })
              }
              className="mt-6"
              name="geimpft_covid_datum3"
              placeholder="Datum"
              errors={null}
            />
            <InputField
              value={anamnese.geimpft_covid_impfstoff3}
              handleChange={(e) =>
                setAnamnese({
                  ...anamnese,
                  geimpft_covid_impfstoff3: e.target.value,
                })
              }
              className="mt-6"
              name="geimpft_covid_impfstoff3"
              placeholder="Impfstoff"
              errors={null}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-4 mt-2">
        <div className="w-6"></div>
        <div className="w-full mt-2 text-xs font-light leading-5">
          (Bitte bringen Sie Ihren Impfausweis oder anderen Impfnachweis zum
          Impftermin mit.)
        </div>
      </div>

      {/* 4 */}
      <div className="flex gap-4 mt-4">
        <div className="flex items-center justify-center w-6 h-6 p-1 text-gray-500 border rounded-full shrink-0 text-2xs border-grey-400">
          4
        </div>
        <div>
          Falls Sie<span className="relative text-2xs -top-2">1</span> bereits
          eine COVID-19-Impfung erhalten haben: Haben Sie
          <span className="relative text-2xs -top-2">1</span> danach eine
          allergische Reaktion entwickelt?
          <div className="flex justify-between pr-4">
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.impfreaktion === "1"}
                name="impfreaktion"
                className="mr-2"
                value={true}
                onChange={handleRadioCheck}
              />{" "}
              ja
            </label>
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.impfreaktion === "0"}
                name="impfreaktion"
                className="mr-2"
                value={false}
                onChange={handleRadioCheck}
              />{" "}
              nein
            </label>
          </div>
        </div>
      </div>
      <div className="flex gap-4 mt-4">
        <div className="w-6"></div>
        <div className="font-light">
          Sind bei Ihnen<span className="relative text-2xs -top-2">1</span>{" "}
          andere ungewöhnliche Reaktionen nach der Impfung aufgetreten?
          <div className="flex justify-between pr-4">
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.ungewoehnliche_reaktionen === "1"}
                name="ungewoehnliche_reaktionen"
                className="mr-2"
                value={true}
                onChange={handleRadioCheck}
              />{" "}
              ja
            </label>
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.ungewoehnliche_reaktionen === "0"}
                name="ungewoehnliche_reaktionen"
                className="mr-2"
                value={false}
                onChange={handleRadioCheck}
              />{" "}
              nein
            </label>
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-6"></div>
        <div className="w-full font-light">
          Wenn ja, welche?
          <br />
          <InputField
            value={anamnese.ungewoehnliche_reaktionen_welche}
            handleChange={(e) =>
              setAnamnese({
                ...anamnese,
                ungewoehnliche_reaktionen_welche: e.target.value,
              })
            }
            className="mt-6"
            name="ungewoehnliche_reaktionen_welche"
            placeholder="Datum"
            errors={null}
          />
        </div>
      </div>

      {/* 5 */}
      <div className="flex gap-4 mt-4">
        <div className="flex items-center justify-center w-6 h-6 p-1 text-gray-500 border rounded-full shrink-0 text-2xs border-grey-400">
          5
        </div>
        <div>
          Wurde bei Ihnen<span className="relative text-2xs -top-2">1</span> in
          der Vergangenheit eine Infektion mit dem Coronavirus (SARS-CoV-2)
          sicher nachgewiesen?
          <div className="flex justify-between pr-4">
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.infektion === "1"}
                name="infektion"
                className="mr-2"
                value={true}
                onChange={handleRadioCheck}
              />{" "}
              ja
            </label>
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.infektion === "0"}
                name="infektion"
                className="mr-2"
                value={false}
                onChange={handleRadioCheck}
              />{" "}
              nein
            </label>
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-6"></div>
        <div className="w-full font-light">
          Wenn ja, wann?
          <br />
          <InputField
            value={anamnese.infektion_datum}
            handleChange={(e) =>
              setAnamnese({
                ...anamnese,
                infektion_datum: e.target.value,
              })
            }
            className="mt-6"
            name="infektion_datum"
            placeholder="Datum"
            errors={null}
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-6"></div>
        <div className="w-full mt-2 text-xs font-light leading-5">
          (Nach einer Infektion mit SARS-CoV-2 wird empfohlen, die Impfung 3
          Monate nach Diagnosestellung durchzuführen. Bitte bringen Sie den
          Nachweis zum Impftermin mit.)
        </div>
      </div>

      {/* 6 */}
      <div className="flex gap-4 mt-4">
        <div className="flex items-center justify-center w-6 h-6 p-1 text-gray-500 border rounded-full shrink-0 text-2xs border-grey-400">
          6
        </div>
        <div>
          Haben Sie<span className="relative text-2xs -top-2">1</span>{" "}
          chronische Erkrankungen oder leiden Sie
          <span className="relative text-2xs -top-2">1</span> an einer
          Immunschwäche (z. B. durch eine Chemotherapie, immunsupprimierende
          Therapie oder andere Medikamente)?
          <div className="flex justify-between pr-4">
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.chronische_erkrankung === "1"}
                name="chronische_erkrankung"
                className="mr-2"
                value={true}
                onChange={handleRadioCheck}
              />{" "}
              ja
            </label>
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.chronische_erkrankung === "0"}
                name="chronische_erkrankung"
                className="mr-2"
                value={false}
                onChange={handleRadioCheck}
              />{" "}
              nein
            </label>
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-6"></div>
        <div className="w-full font-light">
          Wenn ja, welche?
          <br />
          <InputField
            value={anamnese.chronische_erkrankungen}
            handleChange={(e) =>
              setAnamnese({
                ...anamnese,
                chronische_erkrankungen: e.target.value,
              })
            }
            className="mt-6"
            name="chronische_erkrankungen"
            placeholder="Erkrankungen"
            errors={null}
          />
        </div>
      </div>

      {/* 7 */}
      <div className="flex gap-4 mt-4">
        <div className="flex items-center justify-center w-6 h-6 p-1 text-gray-500 border rounded-full shrink-0 text-2xs border-grey-400">
          7
        </div>
        <div>
          Leiden Sie<span className="relative text-2xs -top-2">1</span> an einer
          Blutgerinnungsstörung oder nehmen Sie blutverdünnende Medikamente
          ein??
          <div className="flex justify-between pr-4">
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.blutgerinnerungsstoerung === "1"}
                name="blutgerinnerungsstoerung"
                className="mr-2"
                value={true}
                onChange={handleRadioCheck}
              />{" "}
              ja
            </label>
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.blutgerinnerungsstoerung === "0"}
                name="blutgerinnerungsstoerung"
                className="mr-2"
                value={false}
                onChange={handleRadioCheck}
              />{" "}
              nein
            </label>
          </div>
        </div>
      </div>

      {/* 8 */}
      <div className="flex gap-4 mt-4">
        <div className="flex items-center justify-center w-6 h-6 p-1 text-gray-500 border rounded-full shrink-0 text-2xs border-grey-400">
          8
        </div>
        <div className="w-full">
          Ist bei Ihnen<span className="relative text-2xs -top-2">1</span> eine
          Allergie bekannt?
          <div className="flex justify-between pr-4">
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.allergie === "1"}
                name="allergie"
                className="mr-2"
                value={true}
                onChange={handleRadioCheck}
              />{" "}
              ja
            </label>
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.allergie === "0"}
                name="allergie"
                className="mr-2"
                value={false}
                onChange={handleRadioCheck}
              />{" "}
              nein
            </label>
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-6"></div>
        <div className="w-full font-light">
          Wenn ja, welche?
          <br />
          <InputField
            value={anamnese.allergien}
            handleChange={(e) =>
              setAnamnese({
                ...anamnese,
                allergien: e.target.value,
              })
            }
            className="mt-6"
            name="allergien"
            placeholder="Allergien"
            errors={null}
          />
        </div>
      </div>

      {/* 9 */}
      <div className="flex gap-4 mt-4">
        <div className="flex items-center justify-center w-6 h-6 p-1 text-gray-500 border rounded-full shrink-0 text-2xs border-grey-400">
          9
        </div>
        <div>
          Traten bei Ihnen<span className="relative text-2xs -top-2">1</span>{" "}
          nach einer früheren, anderen Impfung allergische Erscheinungen, hohes
          Fieber, Ohnmachtsanfälle oder andere ungewöhnliche Reaktionen auf?
          <div className="flex justify-between pr-4">
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.fruehe_ungewoehnliche_reaktion === "1"}
                name="fruehe_ungewoehnliche_reaktion"
                className="mr-2"
                value={true}
                onChange={handleRadioCheck}
              />{" "}
              ja
            </label>
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.fruehe_ungewoehnliche_reaktion === "0"}
                name="fruehe_ungewoehnliche_reaktion"
                className="mr-2"
                value={false}
                onChange={handleRadioCheck}
              />{" "}
              nein
            </label>
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-6"></div>
        <div className="w-full font-light">
          Wenn ja, welche?
          <br />
          <InputField
            value={anamnese.fruehe_ungewoehnliche_reaktionen}
            handleChange={(e) =>
              setAnamnese({
                ...anamnese,
                fruehe_ungewoehnliche_reaktionen: e.target.value,
              })
            }
            className="mt-6"
            name="fruehe_ungewoehnliche_reaktionen"
            placeholder="Reaktionen"
            errors={null}
          />
        </div>
      </div>

      {/* 10 */}
      <div className="flex gap-4 mt-4">
        <div className="flex items-center justify-center w-6 h-6 p-1 text-gray-500 border rounded-full shrink-0 text-2xs border-grey-400">
          10
        </div>
        <div>
          Sind Sie<span className="relative text-2xs -top-2">1</span> schwanger?
          <br />
          <span className="text-xs font-light">
            (Eine Impfung mit dem Impfstoff Comirnaty® wird ab dem 2.
            Schwangerschaftsdrittel empfohlen.)
          </span>
          <div className="flex justify-between pr-4">
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.schwanger === "1"}
                name="schwanger"
                className="mr-2"
                value={true}
                onChange={handleRadioCheck}
              />{" "}
              ja
            </label>
            <label className="py-2">
              <input
                type="radio"
                checked={anamnese.schwanger === "0"}
                name="schwanger"
                className="mr-2"
                value={false}
                onChange={handleRadioCheck}
              />{" "}
              nein
            </label>
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-6"></div>
        <div className="w-full font-light">
          Wenn ja, welche SSW?
          <br />
          <InputField
            value={anamnese.ssw}
            handleChange={(e) =>
              setAnamnese({
                ...anamnese,
                ssw: e.target.value,
              })
            }
            className="mt-6"
            name="ssw"
            placeholder="SSW"
            errors={null}
          />
        </div>
      </div>

      <div className="mt-6 font-light text-2xs">
        1) ggf. wird dies von der gesetzlichen Vertretungsperson beantwortet
      </div>

      <Button
        title="Weiter"
        className="mt-8"
        onClick={() => handleSubmit()}
        disabled={!formValid}
      />

      <Footer />
    </>
  );
}
