import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { NavLink } from "react-router-dom";
import UserRow from "../components/UserRow";

export default function Admin() {
  const token = Cookies.get("token");
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState("Nutzer");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/users`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setUsers(res.data.users.map((u) => ({ ...u, checked: false })));
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        alert("Fehler beim Laden der User");
        console.log(err);
      });
  }, []);

  const printAll = () => {
    if (users.filter((u) => u.checked).length === 0) {
      alert("Bitte mindestens einen User auswählen");
      return;
    }
    setLoading("PDF-Druck");
    if (window.confirm("Alle ausgewählten drucken?")) {
      axios
        .post(
          `${process.env.REACT_APP_API}/pdf`,
          {
            files: users
              .filter((u) => u.checked)
              .map((u) => ({
                id: u.id,
                unique_id: u.unique_id,
              })),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          window.open(
            `${process.env.REACT_APP_API}/Anamnesis/${res.data.filename}`,
            "_blank",
            "fullscreen=yes"
          );
        })
        .catch((err) => {
          alert("Fehler beim Drucken der Anamnese");
          console.log(err);
        })
        .finally(() => {
          setUsers(users.map((u) => ({ ...u, checked: false })));
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="py-8 text-xl text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="relative inline w-4 h-4 mr-2 -top-[3px]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
            />
          </svg>
          Impf-Admin
        </h1>
        <NavLink to="search" className="cursor-pointer hover:opacity-80">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </NavLink>
      </div>
      {loading && <Loading what={loading} />}
      {!loading && users && (
        <>
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              className="relative mr-2"
              checked={users.every((u) => u.checked)}
              onChange={() => {
                setUsers(
                  users.map((u) => ({
                    ...u,
                    checked: !users.every((u) => u.checked),
                  }))
                );
              }}
            />
            <div
              className="flex-1 py-2 text-center text-white rounded-xl bg-slate-500 hover:bg-slate-400"
              onClick={printAll}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="relative inline w-4 h-4 mr-2 -top-[1px]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                />
              </svg>
              Alle
            </div>
          </div>
          {users.map((user) => (
            <div key={user.id} className="flex items-center">
              <input
                type="checkbox"
                className="relative mr-2 -top-2"
                checked={user.checked}
                onChange={() => {
                  setUsers(
                    users.map((u) =>
                      u.id === user.id ? { ...u, checked: !u.checked } : u
                    )
                  );
                }}
              />
              <UserRow user={user} />
            </div>
          ))}
        </>
      )}
    </>
  );
}
