import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ProgressBar from "../components/ProgressBar";

export default function Datenschutz() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <h1 className="py-8 text-2xl text-center">Datenschutz</h1>
      <ProgressBar active={3} />
      <br /> <br />
      <Button
        title="Zurück"
        className="mb-8"
        onClick={() => navigate("/einwilligungserklaerung")}
      />
      <div className="prose">
        <h2>Lorem ipsum dolor sit amet.</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam
          molestiae amet molestias distinctio illo soluta iusto porro atque
          ratione dolores alias repellendus harum corporis dicta, rem eum veniam
          est? Vel veritatis dolorum nam libero architecto. Quo repellendus
          fugit ducimus doloribus.
        </p>
        <h2>Lorem ipsum dolor.</h2>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fuga animi
          odit, voluptas beatae nesciunt modi illo eum. Odit non nihil sunt
          voluptatum optio consequuntur unde beatae ipsum eveniet, maxime iste.
        </p>
        <h2>Lorem, ipsum.</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
          laudantium cupiditate eveniet animi deleniti nihil quod repudiandae,
          doloribus maxime. Deleniti mollitia unde natus. Id neque enim
          asperiores perferendis earum sequi magnam modi. At inventore
          architecto iure nobis accusantium veritatis nemo magni est, incidunt
          vel cupiditate rem cumque minima enim fugiat?
        </p>
      </div>
      <Button
        title="Zurück"
        className="mt-8"
        onClick={() => navigate("/einwilligungserklaerung")}
      />
      <Footer />
    </>
  );
}
