import { Navigate, useLocation } from "react-router-dom";
import useAuth from "./hooks/useAuth";

export default function AdminRoute({ children }) {
  const location = useLocation();
  const auth = useAuth();

  if (auth === undefined) return null; // or loading spinner

  return auth ? (
    children
  ) : (
    <Navigate to="/impflogin" replace state={{ from: location }} />
  );
}
