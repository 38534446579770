import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

export default function useAuth() {
  const [loggedIn, setLoggedIn] = useState();

  useEffect(() => {
    const token = Cookies.get("token");
    axios
      .get(`${process.env.REACT_APP_API}/auth`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.status === 200) {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
      })
      .catch((error) => {
        setLoggedIn(false);
        console.error(error);
      });
  }, []);

  return loggedIn;
}
