import { useEffect, useRef, useState } from "react";

export default function InputField({
  name,
  placeholder,
  value,
  handleChange,
  handleBlur = () => {},
  errors = null,
  icon = null,
  type = "text",
  className = "",
}) {
  const ref = useRef();
  const [hasFocus, setFocus] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (document.hasFocus() && ref.current.contains(document.activeElement)) {
      setFocus(true);
    }
  }, []);

  useEffect(() => {
    if (errors && errors[name]) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [errors, name]);

  const icons = {
    person: {
      fill: false,
      path: "M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z",
    },
    personFill: {
      fill: true,
      path: "M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z",
    },
    mobile: {
      fill: false,
      path: "M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z",
    },
    mail: {
      fill: false,
      path: "M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207",
    },
    house: {
      fill: false,
      path: "M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6",
    },
    houseFill: {
      fill: true,
      path: "M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z",
    },
    birthday: {
      fill: false,
      path: "M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z",
    },
    perso: {
      fill: false,
      path: "M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2",
    },
    password: {
      fill: false,
      path: "M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z",
    },
    search: {
      fill: false,
      path: "M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z",
    },
  };

  return (
    <div className={`relative w-full ${className}`}>
      <label
        className={`absolute ease-out duration-200 cursor-text ${
          hasFocus || value !== ""
            ? "-top-5 left-4 text-xs text-slate-800"
            : "top-3 left-4 text-slate-400"
        } ${!icon && hasError ? "text-red-700" : ""}`}
        htmlFor={name}
      >
        {placeholder}
      </label>
      <div className="flex items-center">
        <input
          type={type}
          name={name}
          id={name}
          className={`py-3 px-4 w-full rounded-tl-xl rounded-bl-xl ${
            !icon && "rounded-tr-xl rounded-br-xl"
          } focus:outline-none bg-gray-100 placeholder:text-slate-400`}
          value={value}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
            setFocus(false);
          }}
          ref={ref}
          onFocus={() => setFocus(true)}
        />
        {icon && (
          <div className="relative -left-[1px] p-4 border-slate-100 rounded-tr-xl rounded-br-xl bg-gray-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`w-4 h-4 ${
                hasError ? "text-red-700" : "text-slate-500"
              }`}
              fill={icons[icon].fill ? "currentColor" : "none"}
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={icons[icon].path}
              ></path>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}
