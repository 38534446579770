import virusicon from "../images/coronavirus.svg";
export default function Header() {
  return (
    <>
      <div className="fixed z-50 flex items-center gap-4 p-4 bg-white border-b shadow-xl w-96 border-slate-800 shadow-white">
        <img src={virusicon} alt="Virus" className="w-6 h-6" />
        <div className="text-sm font-light leading-3">
          <span className="tracking-wide uppercase text-slate-800">
            {process.env.REACT_APP_NAME}
          </span>
          <br />
          <span className="text-2xs">
            {process.env.REACT_APP_STREET}, {process.env.REACT_APP_ZIP}{" "}
            {process.env.REACT_APP_CITY}
          </span>
        </div>
      </div>
      <div className="h-16"></div>
    </>
  );
}
